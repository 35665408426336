@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700");

@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
}
p {
  @apply text-gray-600;
}
.container {
  @apply mx-auto px-5;
}
.btn {
  @apply text-white bg-cyan-600 px-10 py-3 rounded-full duration-300;
}
.btn-cont {
  @apply shadow-lg w-fit flex flex-wrap mb-6 justify-start items-center text-white bg-cyan-600 px-5 py-2 rounded-full duration-300;
}
.btn:hover,
.btn-cont:hover {
  @apply bg-cyan-500 shadow-2xl;
}
.sticky {
  @apply fixed bg-cyan-600 shadow;
}
.collapse.show {
  @apply block;
}
.toggler-icon {
  @apply block bg-gray-50 relative duration-300;
  height: 2px;
  width: 30px;
  margin: 6px 0;
}
.active > .toggler-icon:nth-child(1) {
  @apply transform rotate-45;
  top: 7px;
}
.active > .toggler-icon:nth-child(2) {
  @apply opacity-0;
}
.active > .toggler-icon:nth-child(3) {
  top: -8px;
  transform: rotate(135deg);
}
.page-scroll {
  // @apply block px-5 py-2 uppercase text-cyan-50 duration-300;
  @apply block px-5 py-2 uppercase text-gray-500 duration-300;
}
.page-scroll.active,
.page-scroll:hover {
  @apply lg:text-amber-400 text-cyan-500 font-bold;
}
.section-heading {
  @apply text-4xl text-gray-700 font-bold tracking-wide;
}

.icon {
  @apply mb-4 text-cyan-600;
}

.service-title {
  @apply text-gray-800 font-semibold text-lg block mb-3 uppercase;
}

.service-item {
  @apply text-center py-10 px-8 rounded shadow-md bg-white m-3;
}
.news-item {
  @apply grid justify-items-center bg-white m-4 rounded shadow-md max-w-xs min-w-[15rem] cursor-pointer;
}
.news-item:hover {
  @apply shadow-2xl;
  h1 {
    @apply text-amber-400;
  }
}
.feature-title {
  @apply text-gray-800 font-medium block mb-3;
}
.team-item {
  @apply m-3 text-center bg-white shadow duration-300;
}
.team-item:hover {
  @apply shadow-lg;
}
.team-overlay {
  @apply w-full h-full flex items-center justify-center absolute top-0 left-0 bg-gray-200 duration-300 opacity-0;
}
.team-overlay:hover {
  @apply opacity-100;
}

.social-link {
  @apply flex justify-center items-center w-10 h-10 bg-cyan-600 rounded text-lg text-white duration-300;
}
.team-name {
  @apply text-gray-800 font-bold uppercase text-lg my-2;
}

.client-logo {
  @apply opacity-50 duration-300;
}
.client-logo:hover {
  @apply opacity-100;
}
.tns-nav {
  @apply w-full bottom-0 text-center;
}
.tns-nav button {
  @apply w-3 h-3 rounded-full bg-gray-500 opacity-50 mx-1 my-6 duration-300;
}
.tns-nav > .tns-nav-active,
.tns-nav button:hover {
  @apply opacity-100;
}
.tns-nav button:focus {
  @apply outline-none;
}
.pricing-box {
  @apply m-3 text-center p-6 pb-10 shadow rounded duration-300;
}
.pricing-box:hover {
  @apply shadow-lg bg-cyan-100;
}
.package-name {
  @apply text-cyan-600 font-bold uppercase text-sm;
}

.form-input {
  @apply w-full mb-5 bg-white border border-gray-500 px-4 py-2 duration-300 outline-none;
}
.form-input:focus {
  @apply border-cyan-600;
}
.contact-icon {
  @apply w-10 h-10 flex items-center justify-center bg-cyan-600 text-xl text-white rounded-full;
}

.footer-links {
  @apply leading-9 text-gray-100 duration-300;
}
.footer-links:hover {
  @apply text-cyan-500;
}
.footer-icon {
  @apply flex justify-center items-center w-8 h-8 bg-white rounded-full text-sm text-gray-700 duration-300;
}
.footer-icon:hover {
  @apply text-white;
}
.section-heading {
  @apply text-2xl text-gray-700 pb-4;
}

.section-p {
  @apply text-gray-700 pb-2;
}
